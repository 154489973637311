.recharts-default-tooltip {
  min-width: 200px;
}

.recharts-text.recharts-label {
  font-weight: 600;
}

.recharts-text.recharts-cartesian-axis-tick-value {
  font-size: 12px;
}

.recharts-cartesian-axis-line {
  stroke: rgb(204, 204, 204);
}

.xAxis .recharts-cartesian-axis-ticks {
  transform: translate(25px, 0px);
}

.recharts-legend-wrapper {
  left: calc(100% - 145px) !important;
  top: 15% !important;
}

.recharts-custom-rectangle {
  stroke: #73838b;
}

.bar-rectangle-stroke {
  stroke: #b4bdc1;
  stroke-width: 1;
}

#histogram-chard-1 .recharts-cartesian-axis-ticks {
  transform: translate(0px, -2px);
}

.recharts-tooltip-cursor {
  fill: #f3f3f338;
}
