@keyframes szh-menu-show {
  from {
    opacity: 0;
  }
}

@keyframes szh-menu-hide {
  to {
    opacity: 0;
  }
}

.szh-menu {
  padding: 0;
}

.szh-menu__divider {
  margin: 0;
}

.szh-menu__item,
.szh-menu__submenu > .szh-menu__item {
  padding: 8px 35px 8px 18px;
  font-size: 14px;
  line-height: 20px;
}

.szh-menu__item span {
  margin-left: 6px;
}

.szh-menu__submenu span {
  margin-left: 6px;
}

.szh-menu__item--active {
  background-color: #ebebeb;
  color: inherit;
}

.szh-menu__submenu > .szh-menu__item::after {
  font-family: 'Material Icons';
  content: 'arrow_right';
  font-size: 20px;
}

.szh-menu__submenu > .szh-menu__item.szh-menu__item--open::after {
  font-family: 'Material Icons';
  content: 'arrow_left';
  font-size: 20px;
}

.szh-menu__item.szh-menu__item--disabled.my-submenu-item > svg,
.szh-menu__item.szh-menu__item--disabled > svg.experiment-icon {
  opacity: 0.4;
}

.szh-menu--state-opening {
  animation: szh-menu-show 0.15s ease-out;
}
.szh-menu--state-closing {
  animation: szh-menu-hide 0.2s ease-out;
}

.szh-menu--state-open {
  border-radius: 0;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 30%);
}
