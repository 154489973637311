.ag-cell-wrapper {
  height: 100%;
}

.checkbox-selection-cell {
  input {
    width: 55px !important;
    height: 36px !important;
    margin-left: -18px !important;
    margin-top: -9px !important;
  }
}

.checkbox-selection-cell.ag-cell-focus {
  border: none !important;
  border-right: 1px solid #e2e2e2 !important;
  background-color: inherit !important;
}
.ag-row-selected {
  background-color: inherit !important;
}
